(function () {
  'use strict';

  app.filter('daysOrHoursFromNow', controller);
  controller.$inject = ['$translate'];

  function controller($translate) {
    return (input) => {
      try {
        const inputDateTime = new Date(input + 'Z').getTime();
        const nowTime = Date.now();

        const daysDiff = Math.floor((inputDateTime - nowTime) / (1000 * 60 * 60 * 24));
        const hoursDiff = Math.floor((inputDateTime - nowTime) / (1000 * 60 * 60));

        if (daysDiff > 0) {
          return `${daysDiff} ${$translate.instant('g.days.short')}`;
        }

        return (hoursDiff > 0 ? hoursDiff : 1) + ' ' + $translate.instant('g.hours.short');
      } catch {
        return '';
      }
    };
  }
})();
